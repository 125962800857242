@tailwind components;
@tailwind utilities;
@import "~antd/dist/antd.less";

@layer components {
  .panel {
    @apply bg-white rounded border border-black border-opacity-5 px-3 py-2 shadow;
  }
}

@layer utilities {
  .bg-color-none {
    background: none;
  }
  .border-ring {
    border: 1px solid #d9d9d9;
  }
}

.img-scale img {
  @apply duration-200;
}

.img-scale img:hover {
  transform: scale(150%);
}

@import "./mapbox-gl.css";

body {
  position: relative;
}

@ant-prefix: bf;